<template>
  <BasicLayout :initVars="initVars">
    <!-- <template v-slot:layout-mainbox-inner>
    </template> -->
  </BasicLayout>
</template>

<script>
import BasicLayout from '@/layouts/basic';

export default {
  components: { BasicLayout },
  data () {
    return {
      initVars: {}
    };
  },
  // beforeCreate () {
  //   console.log('[Installation][beforeCreate] ... ');
  // },
  created () {
    // console.log('[Installation][created] ... ');
  }
  // beforeMount () {
  //   console.log('[Installation][beforeMount] ... ');
  // },
  // mounted () {
  //   console.log('[Installation][mounted] ... ');
  // },
  // beforeUpdate () {
  //   console.log('[Installation][beforeUpdate] ... ');
  // },
  // updated () {
  //   console.log('[Installation][updated] ... ');
  // }
};
</script>
<style scoped>
</style>
